<template>
    <div>
      <b-table
        striped
        hover
        :items="eLearningList"
        :fields="fields"
        :busy="isLoading"
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
  
        <template v-slot:cell(no)="{ index }">
          {{ (currentPageList - 1) * 10 + index + 1 }}
        </template>
  
        <template v-slot:cell(actions)="{ item }">
          <b-dropdown variant="danger" size="sm">
            <template #button-content> Action </template>
  
            <b-dropdown-item @click="editItem(item)">
              <feather-icon icon="EditIcon" class="mr-75" />
              Update
            </b-dropdown-item>
  
            <b-dropdown-item @click="deleteItem(item.uuid)">
              <feather-icon icon="TrashIcon" class="mr-75" />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
  
      <b-modal
        id="form-post-modal"
        centered
        :title="editIdModal != null ? 'Edit E-Learning Category' : 'Add E-Learning Category'"
        size="lg"
        no-close-on-backdrop
        hide-footer
      >
        <ValidationObserver>
          <div class="form">
            <div class="form-group">
              <label>Nama E-Learning Category:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Title"
                  v-model="formPayloadPost.name"
                />
                <small
                  v-for="(validation, index) in validationsForm.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </div>
          <div v-if="isLoading">
            <br />
            <b-spinner class="mb-2" variant="primary" /><br />
          </div>
          <b-button
            v-if="editIdModal == null"
            variant="primary"
            @click="createItem()"
            v-bind:disabled="isLoading"
          >
            Save E-Learning Category
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="updateItem()"
            v-bind:disabled="isLoading"
          >
            Edit E-Learning Category
          </b-button>
        </ValidationObserver>
      </b-modal>
  
      <div
        v-if="resultData.itemsPerPage > 0"
        class="mt-3 d-flex justify-content-between align-items-center"
      >
        <small
          >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
          {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
          {{ resultData.totalItems }}</small
        >
        <b-pagination
          class="justify-content-end"
          v-model="currentPageList"
          :total-rows="resultData.totalItems"
          :per-page="resultData.itemsPerPage"
          aria-controls="my-table"
          @change="getData"
        ></b-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import {
    ValidationProvider,
    ValidationObserver,
    configure,
  } from "vee-validate";
  import { required } from "@validations";
  import Ripple from "vue-ripple-directive";
  configure({
    classes: {
      valid: "is-valid",
      invalid: "is-invalid",
      dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
      // ...
    },
  });
  import {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BPagination,
    BDropdownItem,
    BButton,
    VBModal,
  } from "bootstrap-vue";
  import _ from "lodash";
  export default {
    components: {
      BTable,
      BSpinner,
      BBadge,
      BImg,
      BDropdown,
      BDropdownItem,
      BPagination,
      BButton,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    props: {
      eLearning: {
        type: "",
      },
      formPayload: {
        type: "",
      },
      validations: {
        type: "",
      },
      editId: {
        type: "",
      },
      result: {
        type: "",
      },
      isLoading: {
        type: Boolean,
      },
      currentPage: {
        type: Number,
      },
      getData: {
        type: Function,
      },
      deleteItem: {
        type: Function,
      },
      editItem: {
        type: Function,
      },
      updateItem: {
        type: Function,
      },
      createItem: {
        type: Function,
      },
    },
    watch: {
      isLoading(value) {
        this.isLoadingComponent = value;
      },
      eLearning(value) {
        this.eLearningList = value;
      },
      formPayload(value) {
        this.formPayloadPost = value;
      },
      validations(value) {
        this.validationsForm = value;
      },
      currentPage(value) {
        this.currentPageList = value;
      },
      result(value) {
        this.resultData = value;
      },
      editId(value) {
        this.editIdModal = value;
      },
      // filter: {
      //   handler(value) {
      //     this.$emit("filter", value);
      //   },
      //   deep: true,
      // },
    },
    data() {
      return {
        required,
        isLoadingComponent: true,
        currentPageList: 1,
        resultData: {},
        eLearningList: [],
        editIdModal: null,
        formPayloadPost: {
          name: "",
        },
        validationsForm: "",
        fields: [
          { key: "no" },
          { key: "name" },
          { key: "actions" },
        ],
        //   filter: {
        //     order_type: "desc",
        //   },
      };
    },
    computed: {
      rows() {
        return this.eLearningList.length;
      },
    },
  };
  </script>
  
  <style></style>
  