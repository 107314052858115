var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.eLearningList,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Update ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(item.uuid)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete ")],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"form-post-modal","centered":"","title":_vm.editIdModal != null ? 'Edit E-Learning Category' : 'Add E-Learning Category',"size":"lg","no-close-on-backdrop":"","hide-footer":""}},[_c('ValidationObserver',[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nama E-Learning Category:")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadPost.name),expression:"formPayloadPost.name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Title"},domProps:{"value":(_vm.formPayloadPost.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayloadPost, "name", $event.target.value)}}}),_vm._l((_vm.validationsForm.name),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),(_vm.isLoading)?_c('div',[_c('br'),_c('b-spinner',{staticClass:"mb-2",attrs:{"variant":"primary"}}),_c('br')],1):_vm._e(),(_vm.editIdModal == null)?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.createItem()}}},[_vm._v(" Save E-Learning Category ")]):_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.updateItem()}}},[_vm._v(" Edit E-Learning Category ")])],1)],1),(_vm.resultData.itemsPerPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1)+" to "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1 * _vm.resultData.itemCount)+" from "+_vm._s(_vm.resultData.totalItems))]),_c('b-pagination',{staticClass:"justify-content-end",attrs:{"total-rows":_vm.resultData.totalItems,"per-page":_vm.resultData.itemsPerPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPageList),callback:function ($$v) {_vm.currentPageList=$$v},expression:"currentPageList"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }